import styled from 'styled-components';

export const SystemSettings = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 2.93rem;
  background-color: #fbfbfb;
`;

export const IconCard = styled.div`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 100%;
  text-align: center;
  margin: none;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none; /* Bloqueia a seleção de texto */

  &.highlighted-card {
    grid-column: span 2; /* Ocupa duas colunas */

    h1 {
      font-size: 40px; /* Aumenta o tamanho da fonte de h1 */
    }

    span {
      font-size: 15px; /* Aumenta o tamanho da fonte do span */
    }
  }
`;

export const IconCardContent = styled.div`
  p {
    color: #7f8c8d;
    margin-top: 10px;
  }

  h1 {
    color: #2c3e50;
    font-size: 20px;
    margin: 10px 0;
  }

  span {
    font-size: 12px;
    color: #7f8c8d;
  }
`;

export const PageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between; /* Para que o conteúdo fique espaçado */
  align-items: center;
  margin-top: 20px;
`;

export const Body1 = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 0rem 5.5rem 0rem;
`;

export const Row01 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Grid com 4 colunas */
  gap: 1rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & + & {
    margin-top: 2rem;
  }
`;

export const ToggleButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const ToggleButton = styled.button<{ isActive: boolean }>`
  padding: 5px 15px;
  border: none;
  background-color: ${props => (props.isActive ? '#2966F5' : '#E0E0E0')};
  color: ${props => (props.isActive ? '#fff' : '#000')};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => (props.isActive ? '#5789F7' : '#D0D0D0')};
  }
`;

export const SkeletonCard = styled.div`
  border-radius: 15px;
  padding: 5px;
  width: 100%;
  margin: none;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none; /* Bloqueia a seleção de texto */

  &.highlighted-card {
    grid-column: span 2; /* Ocupa duas colunas */

    h1 {
      font-size: 40px; /* Aumenta o tamanho da fonte de h1 */
    }

    span {
      font-size: 15px; /* Aumenta o tamanho da fonte do span */
    }
  }
`;
