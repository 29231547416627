import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import GlobalStyle from './styles/global';

import { RouterConfig } from './navigation/RouterConfig';

import AppProvider from './hooks';
import { initFacebookSdk } from './helpers/facebook/init.js';
// import WhatsAppWidget from './components/WhatsAppWidget';

initFacebookSdk();

const App: React.FC = () => {
  return (
    <AppProvider>
      <GlobalStyle />
      <DndProvider backend={HTML5Backend}>
        <RouterConfig />
        {/* <WhatsAppWidget /> */}
      </DndProvider>
    </AppProvider>
  );
};

export default App;
