import React, { useEffect, useMemo } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import UploadFile from '../../components/ImportFileSteps/UploadFile';
import CombineFields from '../../components/ImportFileSteps/CombineFields';
import FinishImport from '../../components/ImportFileSteps/FinishImport';
import {
  StepsItemGroupStepsContent,
  StepsItemGroupStep,
  StepsItemGroupWraper,
  StepsItemGroup,
} from './styles';
import { useCustomTranslation } from '../../hooks/custom-translation';
import {
  Body,
  ButtonHeader,
  Container,
  Content,
  PageHeader,
} from '../../styles/contents';
import { H3 } from '../../styles/fonts';

import * as CONSTANTS from '../../navigation/constants';
// eslint-disable-next-line import/extensions
// import { useTransaction } from '../../navigation/TransactionContext';
import { useGetDashboardDataQuery } from '../../services/dashboard';

const ContactImportWizard = () => {
  // const { dashboardData: files } = useTransaction();
  const { data: files } = useGetDashboardDataQuery({});

  const navigate = useNavigate();

  useEffect(() => {
    if (files && files.length) {
      const customerPF = files.find((file: any) => file.customerType === 'PF');
      // const customerPJ = files.find((file: any) => file.customerType === 'PJ');

      const checkUpgrade = (customer: any) => {
        if (customer?.enrichment >= customer?.maxQuantityEnrichment) {
          navigate(CONSTANTS.UPGRADE);
        }
      };

      if (customerPF) {
        checkUpgrade(customerPF);
      }

      // if (customerPJ) {
      //   checkUpgrade(customerPJ);
      // }
    }
  }, [files, navigate]);

  const [current, setCurrent] = React.useState(0);
  const [importerId, setImporterId] = React.useState('');
  const change = (a: any) => {
    setCurrent(a);
  };

  const { tImportContact } = useCustomTranslation('importContact');

  const steps = useMemo(
    () => [
      {
        title: `${tImportContact('upload_file')}`,
        content: (
          <UploadFile
            change={change}
            setImporterId={setImporterId}
            importerId={importerId}
          />
        ),
      },
      {
        title: `${tImportContact('combine_fields')}`,
        content: <CombineFields change={change} importerId={importerId} />,
      },
      {
        title: `${tImportContact('import')}`,
        content: <FinishImport importerId={importerId} />,
      },
    ],
    [importerId, tImportContact],
  );

  const backToContacts = () => {
    navigate(CONSTANTS.DATABASE_MANAGEMENT_PF);
  };
  return (
    <Container>
      <PageHeader>
        <ButtonHeader
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={backToContacts}
        />
        <H3 weight="medium">{tImportContact('import_contacts_pf')}</H3>
      </PageHeader>
      <Body>
        <Content>
          <StepsItemGroupWraper>
            <StepsItemGroup
              current={current}
              type="default"
              direction="horizontal"
            >
              <StepsItemGroupStep title={steps[0].title} />
              <StepsItemGroupStep title={steps[1].title} />
              <StepsItemGroupStep title={steps[2].title} />
            </StepsItemGroup>
            <StepsItemGroupStepsContent>
              {steps[current].content}
            </StepsItemGroupStepsContent>
          </StepsItemGroupWraper>
        </Content>
      </Body>
    </Container>
  );
};

export default ContactImportWizard;
