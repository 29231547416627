import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { CloseCircleFilled } from '@ant-design/icons';
import { FormInstance, Rule } from 'antd/lib/form';

import { useDeepCompareEffectForMaps } from '../../../helpers/maps/use-deep-compare';

import { Input, InputContainer } from './styles';

export type PlaceResult = google.maps.places.PlaceResult;

interface Props extends google.maps.places.AutocompleteOptions {
  label?: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
  value: string;
  setPlace(place: PlaceResult): void;
}

const Autocomplete: React.FC<Props> = ({
  name,
  form,
  setPlace,
  value,
  ...options
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [input, setInput] = useState<google.maps.places.Autocomplete>();
  const [inputError, setInputError] = useState<boolean>(false);
  const [alreadyPlace, setAlreadyPlace] = useState<boolean>(false);

  const onSelect = useCallback(
    (place: PlaceResult) => {
      form.setFieldsValue({ [name]: place.formatted_address ?? place.name });
      setPlace(place);
    },
    [form, name, setPlace],
  );

  const onErase = useCallback(() => {
    onSelect({});
  }, [onSelect]);

  const handleChangePlace = useCallback(() => {
    if (input) {
      const place = input.getPlace();
      onSelect(place);
      setInputError(!Object.keys(place).length);
      setAlreadyPlace(true);
    }
  }, [input, onSelect]);

  const handleChangeInput = useCallback(async () => {
    if (alreadyPlace) {
      onErase();
      try {
        await form.validateFields([name]);
      } catch {
        setInputError(true);
      }
    }
  }, [alreadyPlace, form, name, onErase]);

  const handleClear = () => {
    if (ref.current) {
      ref.current.value = '';
    }
    form.setFieldsValue({ address: '' });
  };

  useEffect(() => {
    if (ref.current && !input) {
      setInput(
        new window.google.maps.places.Autocomplete(ref.current, {
          componentRestrictions: { country: 'br' },
        }),
      );
    }
  }, [ref, input]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (input) {
      input.setOptions(options);
    }
  }, [input, options]);

  useEffect(() => {
    if (input) {
      ['place_changed'].forEach(eventName =>
        google.maps.event.clearListeners(input, eventName),
      );

      input.addListener('place_changed', handleChangePlace);
    }
  }, [input, handleChangePlace]);

  useEffect(() => {
    if (value && input && ref.current) {
      ref.current.value = value;
    }
  }, [input, value]);

  return (
    <InputContainer status={inputError && alreadyPlace ? 'error' : 'default'}>
      <Input>
        <input ref={ref} onChange={handleChangeInput} />
        <CloseCircleFilled onClick={() => handleClear()} />
      </Input>
      {/* <IconContainer>
        <SearchOutlined />
      </IconContainer> */}
    </InputContainer>
  );
};

export default memo(Autocomplete);
