import { CloseOutlined, KeyOutlined } from '@ant-design/icons';

import { useCallback, useEffect } from 'react';
import getErrors from '../../helpers/errors';
import { ShowFormMessage } from '../../helpers/message';
import { Validators } from '../../helpers/validators';
import { useCustomTranslation } from '../../hooks/custom-translation';
import {
  ChangeUserProps,
  useUpdatePasswordMutation,
} from '../../services/users';
import Form, { FormActions, FormValidation, FormWrapper } from '../Form';
import Modal from '../Modal';

import {
  ActionBar,
  Button,
  CloseButton,
  Column,
  LayoutPage,
  PageHeader,
  PageHeaderTitle,
  RightButtons,
  Sider1,
  Wrapper,
} from './styles';
import { H5 } from '../../styles/fonts';
import FormInput from '../FormInput';
import { useAuth } from '../../hooks/auth';

interface ModalAddUserProps {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
}

const PasswordChangeModal = ({
  onOk,
  onCancel,
  visible,
}: ModalAddUserProps) => {
  const { tCommonWords } = useCustomTranslation(
    'user',
    'commonWords',
    'modals',
    'systemSettings',
  );

  const [updatePassword, configUpdatePassword] = useUpdatePasswordMutation();

  const { userInfo } = useAuth();

  const [form] = Form.useForm<ChangeUserProps>();

  const handleCancel = () => {
    onCancel();
    onOk();
    form.resetFields();
  };

  const handleSubmit = async ({
    newPassword,
    currentPassword,
  }: ChangeUserProps) => {
    await updatePassword({
      newPassword,
      currentPassword,
      id: userInfo.sub,
    }).unwrap();
  };

  const formValidation: FormValidation<ChangeUserProps> = {
    currentPassword: [Validators.Required()],
    newPassword: [Validators.Required()],
    confirmNewPassword: [Validators.SameAs('newPassword')],
  };

  const showMessageUpdatePassword = useCallback(() => {
    const { isSuccess, isError, error } = configUpdatePassword;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Senha alterada com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      form.resetFields();
      onCancel();
    }
  }, [configUpdatePassword, form, onCancel]);

  useEffect(showMessageUpdatePassword, [showMessageUpdatePassword]);

  return (
    <>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={false}
        width="35rem"
      >
        <LayoutPage>
          <Sider1 />
          <Wrapper>
            <FormWrapper>
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                form={form}
                requiredMark={false}
              >
                <PageHeader>
                  <PageHeaderTitle>Mudar senha</PageHeaderTitle>

                  <CloseButton
                    type="text"
                    icon={<CloseOutlined style={{ fontSize: 18 }} />}
                    onClick={handleCancel}
                  />
                </PageHeader>

                <Column>
                  <Form.Item
                    label={<H5 weight="medium">Senha atual</H5>}
                    name="currentPassword"
                    rules={formValidation.currentPassword}
                  >
                    <FormInput.Password size="large" prefix={<KeyOutlined />} />
                  </Form.Item>
                  <Form.Item
                    label={<H5 weight="medium">Nova senha</H5>}
                    name="newPassword"
                    rules={formValidation.newPassword}
                  >
                    <FormInput.Password size="large" prefix={<KeyOutlined />} />
                  </Form.Item>
                  <Form.Item
                    label={<H5 weight="medium">Repita sua nova senha</H5>}
                    name="confirmNewPassword"
                    rules={formValidation.confirmNewPassword}
                  >
                    <FormInput.Password size="large" prefix={<KeyOutlined />} />
                  </Form.Item>
                </Column>
                <FormActions>
                  <ActionBar>
                    <RightButtons>
                      <Button
                        type="default"
                        size="large"
                        onClick={handleCancel}
                      >
                        {tCommonWords('cancel')}
                      </Button>
                      <Button type="primary" size="large" htmlType="submit">
                        {tCommonWords('save')}
                      </Button>
                    </RightButtons>
                  </ActionBar>
                </FormActions>
              </Form>
            </FormWrapper>
          </Wrapper>
        </LayoutPage>
      </Modal>
    </>
  );
};

export default PasswordChangeModal;
