import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCustomTranslation } from '../../../hooks/custom-translation';

import * as CONSTANTS from '../../../navigation/constants';

import {
  Container,
  Body,
  Vector,
  Wrapper,
  TextTitle,
  TextSubTitle,
  ButtonFinishImport,
  Vector1,
  Vector2,
} from './styles';
import ProgressBar from '../../ProgressBar';
import { statusMap } from '../../../services/fileImporter';
import { useGetFileImporterPjByIdQuery } from '../../../services/fileImporterPJ';

interface ComponentsStatus {
  [key: number]: React.ComponentType<any>;
}

const FinishImport = ({ importerId }: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { tImportContact } = useCustomTranslation('importContact');
  const [statusId, setStatusId] = useState(1);

  const handleFinishImport = () => {
    navigate(CONSTANTS.DATABASE_MANAGEMENT_PJ);
  };

  const { data: importer, refetch: filesRefetch } =
    useGetFileImporterPjByIdQuery(id || importerId);

  useEffect(() => {
    const interval = setInterval(() => {
      filesRefetch();
    }, 10000);

    return () => clearInterval(interval);
  }, [id, importerId, filesRefetch]);

  useEffect(() => {
    if (importer?.fileImportStatusPjId === 1) setStatusId(1);
    else if (importer?.fileImportStatusPjId === 3) setStatusId(2);
    else if (importer?.fileImportStatusPjId === 4) setStatusId(3);
    else {
      setStatusId(0);
    }
  }, [importer]);

  const componentsStatus: ComponentsStatus = {
    0: Vector,
    1: Vector,
    2: Vector1,
    3: Vector2,
  };

  const messageStatus = (status: any) => {
    switch (status) {
      case 0:
        return tImportContact('await_message_instruction');
      case 1:
      case 2:
        return tImportContact('await_message_instruction_segmentation_pj');
      case 3:
        return tImportContact('finish_message_instruction_segmentation');
      default:
        return '';
    }
  };

  const titleStatus = (status: any) => {
    switch (status) {
      case 0:
      case 1:
        return tImportContact('base_contacts_processing');
      case 2:
        return tImportContact('segmentation_base_contacts_pj');
      case 3:
        return tImportContact('finish_segmentation_base_contacts_pj');
      default:
        return tImportContact('base_contacts_processing');
    }
  };

  const getStatus = (progress: any) => {
    return statusMap[progress] || null;
  };

  const VectorImage = componentsStatus[statusId] || Vector;

  return (
    <Container>
      <Body>
        <VectorImage />
        <Wrapper>
          <TextTitle>{titleStatus(getStatus(importer?.progress))}</TextTitle>
          <TextSubTitle>
            {messageStatus(getStatus(importer?.progress))}
          </TextSubTitle>
          <ButtonFinishImport
            type="primary"
            size="large"
            onClick={handleFinishImport}
          >
            {tImportContact('back_to_import_list')}
          </ButtonFinishImport>
          <ProgressBar fileImportStatusId={statusId} size="default" hasLabel />
        </Wrapper>
      </Body>
    </Container>
  );
};

export default FinishImport;
