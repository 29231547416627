import React, { useCallback, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useNavigate } from 'react-router-dom';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { H3, H5, Body } from '../../../styles/fonts';
import * as CONSTANTS from '../../../navigation/constants';

import {
  WizardWelcome,
  Sider1,
  Wrapper,
  PageHeader,
  TopBar,
  Fields,
  Row01,
  Row021,
  ActionBar,
  CheckboxGroup,
} from './styles';
import getErrors from '../../../helpers/errors';
import { ShowFormMessage } from '../../../helpers/message';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import Input from '../../../components/FormInput/Input';
import FormCheckbox from '../../../components/FormCheckbox';
import Form from '../../../components/Form';
import { usePostPotentialExportMutation } from '../../../services/potentialAudienceExport';
import { useGetDashboardDataQuery } from '../../../services/dashboard';

interface ModalExportFileProps {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  potentialAudienceId: string;
  fileImportSource: string;
}

const ModalExportPotentialAudience = ({
  onOk,
  onCancel,
  visible,
  potentialAudienceId,
  fileImportSource,
}: ModalExportFileProps) => {
  const { tModalExportFile } = useCustomTranslation('modalExportFile');

  const dataTotal =
    fileImportSource === 'PF'
      ? [
          { value: 1, label: 'CPF', fieldCategory: 1, fieldKey: 'E_CPF' },
          { value: 52, label: 'Nome', fieldCategory: 1, fieldKey: 'E_NOME' },
          { value: 61, label: 'RG', fieldCategory: 1, fieldKey: 'E_RG' },
          {
            value: 17,
            label: 'Data Nasc.',
            fieldCategory: 1,
            fieldKey: 'E_DATA_NASCIMENTO',
          },
          { value: 70, label: 'Sexo', fieldCategory: 1, fieldKey: 'E_SEXO' },
          {
            value: 26,
            label: 'Est. Civil',
            fieldCategory: 1,
            fieldKey: 'E_ESTADO_CIVIL',
          },
          {
            value: 23,
            label: 'Escolar.',
            fieldCategory: 1,
            fieldKey: 'E_ESCOLARIDADE',
          },
          {
            value: 57,
            label: 'Profissão',
            fieldCategory: 1,
            fieldKey: 'E_PROFISSAO',
          },
          {
            value: 18,
            label: 'Email1',
            fieldCategory: 1,
            fieldKey: 'E_EMAIL1',
          },
          {
            value: 19,
            label: 'Email2',
            fieldCategory: 1,
            fieldKey: 'E_EMAIL2',
          },
          {
            value: 20,
            label: 'Email3',
            fieldCategory: 1,
            fieldKey: 'E_EMAIL3',
          },
          {
            value: 21,
            label: 'Email4',
            fieldCategory: 1,
            fieldKey: 'E_EMAIL4',
          },
          {
            value: 22,
            label: 'Email5',
            fieldCategory: 1,
            fieldKey: 'E_EMAIL5',
          },
          { value: 5, label: 'Cel1', fieldCategory: 1, fieldKey: 'E_CEL1' },
          { value: 6, label: 'Cel2', fieldCategory: 1, fieldKey: 'E_CEL2' },
          { value: 7, label: 'Cel3', fieldCategory: 1, fieldKey: 'E_CEL3' },
          { value: 8, label: 'Cel4', fieldCategory: 1, fieldKey: 'E_CEL4' },
          { value: 9, label: 'Cel5', fieldCategory: 1, fieldKey: 'E_CEL5' },
          { value: 35, label: 'Fixo1', fieldCategory: 1, fieldKey: 'E_FIXO1' },
          { value: 36, label: 'Fixo2', fieldCategory: 1, fieldKey: 'E_FIXO2' },
          { value: 37, label: 'Fixo3', fieldCategory: 1, fieldKey: 'E_FIXO3' },
          { value: 38, label: 'Fixo4', fieldCategory: 1, fieldKey: 'E_FIXO4' },
          { value: 39, label: 'Fixo5', fieldCategory: 1, fieldKey: 'E_FIXO5' },
          { value: 10, label: 'CEP', fieldCategory: 1, fieldKey: 'E_CEP' },
          {
            value: 47,
            label: 'Logr. Nome',
            fieldCategory: 1,
            fieldKey: 'E_LOGR_NOME',
          },
          {
            value: 48,
            label: 'Logr. Número',
            fieldCategory: 1,
            fieldKey: 'E_LOGR_NUMERO',
          },
          {
            value: 49,
            label: 'Logr. Tipo',
            fieldCategory: 1,
            fieldKey: 'E_LOGR_TIPO',
          },
          {
            value: 46,
            label: 'Logr. Compl.',
            fieldCategory: 1,
            fieldKey: 'E_LOGR_COMPLEMENTO',
          },
          {
            value: 50,
            label: 'Logr. Título',
            fieldCategory: 1,
            fieldKey: 'E_LOGR_TITULO',
          },
          {
            value: 11,
            label: 'Cidade',
            fieldCategory: 1,
            fieldKey: 'E_CIDADE',
          },
          { value: 73, label: 'UF', fieldCategory: 1, fieldKey: 'E_UF' },
          {
            value: 45,
            label: 'Lat.',
            fieldCategory: 1,
            fieldKey: 'E_LATITUDE',
          },
          {
            value: 51,
            label: 'Long.',
            fieldCategory: 1,
            fieldKey: 'E_LONGITUDE',
          },
          {
            value: 59,
            label: 'Renda HH',
            fieldCategory: 1,
            fieldKey: 'E_RENDA_HOUSEHOLD',
            fieldType: 'float',
          },
          {
            value: 60,
            label: 'Renda Ind Presum.',
            fieldCategory: 1,
            fieldKey: 'E_RENDA_INDIVIDUAL_PRESUMIDA',
            fieldType: 'float',
          },
          {
            value: 63,
            label: 'Score Abr.',
            fieldCategory: 1,
            fieldKey: 'E_SCORE_ABER',
            fieldType: 'float',
          },
          {
            value: 64,
            label: 'Score Cons.',
            fieldCategory: 1,
            fieldKey: 'E_SCORE_CONS',
            fieldType: 'float',
          },
          {
            value: 65,
            label: 'Score Extr.',
            fieldCategory: 1,
            fieldKey: 'E_SCORE_EXTR',
            fieldType: 'float',
          },
          {
            value: 66,
            label: 'Score Fin.',
            fieldCategory: 1,
            fieldKey: 'E_SCORE_FINANCEIRO',
            fieldType: 'float',
          },
          {
            value: 67,
            label: 'Score Neur.',
            fieldCategory: 1,
            fieldKey: 'E_SCORE_NEUR',
            fieldType: 'float',
          },
          {
            value: 68,
            label: 'Score Soci.',
            fieldCategory: 1,
            fieldKey: 'E_SCORE_SOCI',
            fieldType: 'float',
          },
          {
            value: 62,
            label: 'Risco Fin. Psicol.',
            fieldCategory: 1,
            fieldKey: 'E_RISCO_FINANCEIRO_PSICO',
          },
          {
            value: 75,
            label: 'Vant. Econ.',
            fieldCategory: 1,
            fieldKey: 'E_VANTAGEM_ECONOMICA',
            fieldType: 'float',
          },
          {
            value: 29,
            label: 'FG_Aposent.',
            fieldCategory: 1,
            fieldKey: 'E_FG_APOSENTADO',
          },
          {
            value: 30,
            label: 'FG_Aux. Emerg.',
            fieldCategory: 1,
            fieldKey: 'E_FG_AUXILIO_EMERGENCIAL',
          },
          {
            value: 31,
            label: 'FG_Bolsa Fam.',
            fieldCategory: 1,
            fieldKey: 'E_FG_BOLSA_FAMILIA',
          },
          {
            value: 32,
            label: 'FG_Obito',
            fieldCategory: 1,
            fieldKey: 'E_FG_OBITO',
          },
          {
            value: 33,
            label: 'FG_Part. Empresa',
            fieldCategory: 1,
            fieldKey: 'E_FG_PARTICIP_EMPRESA',
          },
          {
            value: 34,
            label: 'FG_PEP',
            fieldCategory: 1,
            fieldKey: 'E_FG_PEP',
          },
          { value: 3, label: 'Bairro', fieldCategory: 1, fieldKey: 'E_BAIRRO' },
          {
            value: 4,
            label: 'Brinde',
            fieldCategory: 1,
            fieldKey: 'E_BRINDE',
            fieldType: 'float',
          },
          {
            value: 12,
            label: 'Conf. Marca',
            fieldCategory: 1,
            fieldKey: 'E_CONFIANCA_DE_MARCA',
            fieldType: 'float',
          },
          {
            value: 13,
            label: 'Cons. Pot. Digital',
            fieldCategory: 1,
            fieldKey: 'E_CONSUMO_POTENCIAL_DIGITAL',
            fieldType: 'float',
          },
          {
            value: 14,
            label: 'Conveniência',
            fieldCategory: 1,
            fieldKey: 'E_CONVENIENCIA',
            fieldType: 'float',
          },
          {
            value: 16,
            label: 'Custo-Benef.',
            fieldCategory: 1,
            fieldKey: 'E_CUSTO_BENEFICIO',
            fieldType: 'float',
          },
          {
            value: 24,
            label: 'ESG',
            fieldCategory: 1,
            fieldKey: 'E_ESG',
            fieldType: 'float',
          },
          {
            value: 25,
            label: 'Estab.',
            fieldCategory: 1,
            fieldKey: 'E_ESTABILIDADE',
            fieldType: 'float',
          },
          {
            value: 27,
            label: 'Exp. Compra',
            fieldCategory: 1,
            fieldKey: 'E_EXPERIENCIA_DE_COMPRA',
            fieldType: 'float',
          },
          {
            value: 28,
            label: 'Fac. Pag.',
            fieldCategory: 1,
            fieldKey: 'E_FACILIDADE_DE_PAGAMENTO',
            fieldType: 'float',
          },
          {
            value: 43,
            label: 'Inov.',
            fieldCategory: 1,
            fieldKey: 'E_INOVACAO',
            fieldType: 'float',
          },
          {
            value: 44,
            label: 'Instab.',
            fieldCategory: 1,
            fieldKey: 'E_INSTABILIDADE',
            fieldType: 'float',
          },
          {
            value: 53,
            label: 'Nome Segm.',
            fieldCategory: 1,
            fieldKey: 'E_NOME_SEGMENTO',
          },
          {
            value: 54,
            label: 'Op. Pag.',
            fieldCategory: 1,
            fieldKey: 'E_OPCAO_PAG',
            fieldType: 'float',
          },
          {
            value: 55,
            label: 'Premium',
            fieldCategory: 1,
            fieldKey: 'E_PREMIUM',
            fieldType: 'float',
          },
          {
            value: 56,
            label: 'Premium Serv.',
            fieldCategory: 1,
            fieldKey: 'E_PREMIUM_SERVICO',
            fieldType: 'float',
          },
          {
            value: 58,
            label: 'Qtd PEP Rel.',
            fieldCategory: 1,
            fieldKey: 'E_QTD_PEP_RELACIONADAS',
            fieldType: 'long',
          },
          {
            value: 69,
            label: 'Setor Cens.',
            fieldCategory: 1,
            fieldKey: 'E_SETOR_CENSITARIO',
          },
          {
            value: 71,
            label: 'Status Rec. Fed.',
            fieldCategory: 1,
            fieldKey: 'E_STATUS_RECEITA_FEDERAL',
          },
          {
            value: 72,
            label: 'Status Social',
            fieldCategory: 1,
            fieldKey: 'E_STATUS_SOCIAL',
            fieldType: 'float',
          },
          {
            value: 74,
            label: 'Val. Sazonal',
            fieldCategory: 1,
            fieldKey: 'E_VALOR_SAZONAL',
            fieldType: 'long',
          },
          {
            value: 76,
            label: 'Varejo Fís.',
            fieldCategory: 1,
            fieldKey: 'E_VAREJO_FISICO',
            fieldType: 'float',
          },
        ]
      : [
          {
            value: 4,
            label: 'CNPJ',
            fieldCategory: 1,
            fieldKey: 'cnpj',
          },
          {
            value: 52,
            label: 'Razão Social',
            fieldCategory: 1,
            fieldKey: 'razao_social',
          },
          {
            value: 42,
            label: 'Nome Fantasia',
            fieldCategory: 1,
            fieldKey: 'nome_fantasia',
          },
          {
            value: 41,
            label: 'Natureza Jurídica',
            fieldCategory: 1,
            fieldKey: 'descricao_natureza_juridica',
          },
          { value: 39, label: 'Matriz', fieldCategory: 1, fieldKey: 'matriz' },
          {
            value: 25,
            label: 'Data de Fundação',
            fieldCategory: 1,
            fieldKey: 'data_fundacao',
          },
          {
            value: 2,
            label: 'Capital Social',
            fieldCategory: 1,
            fieldKey: 'capital_social',
            fieldType: 'float',
          },
          {
            value: 51,
            label: 'Porte',
            fieldCategory: 1,
            fieldKey: 'porte',
          },
          {
            value: 49,
            label: 'Opção MEI',
            fieldCategory: 1,
            fieldKey: 'opcao_mei',
          },
          {
            value: 50,
            label: 'Opção Simples',
            fieldCategory: 1,
            fieldKey: 'opcao_simples',
          },
          {
            value: 35,
            label: 'Faturamento Presumido Anual',
            fieldCategory: 1,
            fieldKey: 'faturamento_presumido_anual',
            fieldType: 'float',
          },
          {
            value: 37,
            label: 'Logradouro',
            fieldCategory: 1,
            fieldKey: 'logradouro',
          },
          {
            value: 54,
            label: 'Tipo de Logradouro',
            fieldCategory: 1,
            fieldKey: 'tp_logr',
          },
          {
            value: 48,
            label: 'Número',
            fieldCategory: 1,
            fieldKey: 'num',
          },
          {
            value: 18,
            label: 'Complemento',
            fieldCategory: 1,
            fieldKey: 'compl',
          },
          {
            value: 1,
            label: 'Bairro',
            fieldCategory: 1,
            fieldKey: 'bairro',
          },
          {
            value: 3,
            label: 'CEP',
            fieldCategory: 1,
            fieldKey: 'cep',
          },
          {
            value: 10,
            label: 'Cidade',
            fieldCategory: 1,
            fieldKey: 'cidade',
          },
          {
            value: 55,
            label: 'UF',
            fieldCategory: 1,
            fieldKey: 'uf',
          },
          {
            value: 34,
            label: 'E-mail',
            fieldCategory: 1,
            fieldKey: 'email',
          },
          { value: 26, label: 'DDD 1', fieldCategory: 1, fieldKey: 'ddd_1' },
          {
            value: 43,
            label: 'Nome Sócio 1',
            fieldCategory: 1,
            fieldKey: 'nome_socio1',
          },
          {
            value: 19,
            label: 'CPF Sócio 1',
            fieldCategory: 1,
            fieldKey: 'cpf_socio1',
          },
          {
            value: 5,
            label: 'Celular Sócio 1',
            fieldCategory: 1,
            fieldKey: 'celular_socio1',
          },
          {
            value: 44,
            label: 'Nome Sócio 2',
            fieldCategory: 1,
            fieldKey: 'nome_socio2',
          },
          {
            value: 20,
            label: 'CPF Sócio 2',
            fieldCategory: 1,
            fieldKey: 'cpf_socio2',
          },
          {
            value: 6,
            label: 'Celular Sócio 2',
            fieldCategory: 1,
            fieldKey: 'celular_socio2',
          },
          {
            value: 45,
            label: 'Nome Sócio 3',
            fieldCategory: 1,
            fieldKey: 'nome_socio3',
          },
          {
            value: 21,
            label: 'CPF Sócio 3',
            fieldCategory: 1,
            fieldKey: 'cpf_socio3',
          },
          {
            value: 7,
            label: 'Celular Sócio 3',
            fieldCategory: 1,
            fieldKey: 'celular_socio3',
          },
          {
            value: 46,
            label: 'Nome Sócio 4',
            fieldCategory: 1,
            fieldKey: 'nome_socio4',
          },
          {
            value: 22,
            label: 'CPF Sócio 4',
            fieldCategory: 1,
            fieldKey: 'cpf_socio4',
          },
          {
            value: 8,
            label: 'Celular Sócio 4',
            fieldCategory: 1,
            fieldKey: 'celular_socio4',
          },
          {
            value: 47,
            label: 'Nome Sócio 5',
            fieldCategory: 1,
            fieldKey: 'nome_socio5',
          },
          {
            value: 23,
            label: 'CPF Sócio 5',
            fieldCategory: 1,
            fieldKey: 'cpf_socio5',
          },
          {
            value: 9,
            label: 'Celular Sócio 5',
            fieldCategory: 1,
            fieldKey: 'celular_socio5',
          },
          {
            value: 11,
            label: 'CNAE Principal',
            fieldCategory: 1,
            fieldKey: 'cnae_principal',
          },
          {
            value: 27,
            label: 'Descrição CNAE Principal',
            fieldCategory: 1,
            fieldKey: 'descricao_cnae_principal',
          },
          {
            value: 53,
            label: 'Situação Cadastral',
            fieldCategory: 1,
            fieldKey: 'situacao_cadastral',
          },
        ];

  const dataEntered = dataTotal.filter(data => data.fieldCategory === 1);

  const [form] = Form.useForm<any>();

  const [searchTerm, setSearchTerm] = useState('');

  const [itemsChecked, setItemsChecked] = useState<any[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [objMetadata, setObjMetadata] = useState({});

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setItemsChecked(
      e.target.checked ? dataTotal.map(value => value.value) : [],
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);

    if (e.target.checked) {
      const newObjMetaData = {};

      dataTotal.forEach(item => {
        Object.assign(newObjMetaData, {
          [`${item.fieldCategory === 3 ? 'E_' : ''}${item.label}`]:
            item.fieldKey,
        });
      });

      setObjMetadata(newObjMetaData);
    } else {
      setObjMetadata({});
    }
  };

  const searchResults = dataTotal.filter(data =>
    data.label.toLowerCase().includes(searchTerm),
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleChecked = (checkedValues: CheckboxValueType[]) => {
    setItemsChecked(
      checkedValues.map(
        value => dataTotal.find(data => data.value === value)?.value,
      ),
    );
    setIndeterminate(
      !!checkedValues.length && checkedValues.length < dataTotal.length,
    );

    checkedValues.map(value =>
      setObjMetadata(prevState => ({
        ...prevState,
        [`${
          dataTotal.find(item => item.value === value)?.fieldCategory === 3
            ? 'E_'
            : ''
        }${dataTotal.find(item => item.value === value)?.label}`]:
          dataTotal.find(item => item.value === value)?.fieldKey,
      })),
    );
  };

  const [postPotentialAudienceExport, configPostPotentialExport] =
    usePostPotentialExportMutation();

  const navigate = useNavigate();
  // const { dashboardData: files } = useTransaction();
  const { data: files } = useGetDashboardDataQuery({});

  const handleSubmit = async () => {
    if (files && files.length) {
      const customerFile = files.find(
        (file: any) => file.customerType === fileImportSource,
      );

      const handleCustomerExport = async (customer: any) => {
        if (customer.leads > customer.maxQuantityLeads) {
          navigate(CONSTANTS.UPGRADE);
        }
        await postPotentialAudienceExport({
          dataSourceId: potentialAudienceId,
          dataSource: fileImportSource,
          fileExportTypeId: 1,
          exportMetadata: objMetadata,
        });
      };

      if (customerFile) {
        await handleCustomerExport(customerFile);
      }
    }
  };

  const showMessagePostFileExport = useCallback(() => {
    const { isSuccess, isError, error } = configPostPotentialExport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }

    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Seu arquivo foi gerado e está sendo processado.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      form.resetFields();
      setItemsChecked([]);
      onCancel();
    }
  }, [configPostPotentialExport, form, onCancel]);

  const handleCancel = () => {
    onCancel();
    onOk();
    form.resetFields();
    setItemsChecked([]);
  };

  useEffect(showMessagePostFileExport, [showMessagePostFileExport]);

  return (
    <>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={false}
        width="41.25rem"
      >
        <WizardWelcome>
          <Sider1 />
          <Wrapper>
            <PageHeader>
              <H3 weight="medium">{tModalExportFile('export_public')}</H3>
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCancel}
              />
            </PageHeader>
            <H5 weight="medium">
              {tModalExportFile('what_fields_do_you_want')}
            </H5>
            <TopBar>
              <Input
                size="large"
                placeholder={tModalExportFile('search_for_fields')}
                style={{ width: '100%' }}
                onChange={handleInputChange}
              />
              <FormCheckbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                <Body weight="medium">{tModalExportFile('select_all')}</Body>
              </FormCheckbox>
            </TopBar>
            <Form form={form} onFinish={handleSubmit}>
              <CheckboxGroup onChange={handleChecked} value={itemsChecked}>
                {searchTerm !== '' && (
                  <Fields>
                    <Form.Item name="searchResults">
                      <Row021>
                        {searchResults.map((option: any) => (
                          <FormCheckbox key={option.value} value={option.value}>
                            {option.label}
                          </FormCheckbox>
                        ))}
                      </Row021>
                    </Form.Item>
                  </Fields>
                )}
                {searchTerm === '' && (
                  <Fields>
                    <H5 weight="medium">{tModalExportFile('data_entered')}</H5>

                    <Form.Item name="dataEntered">
                      <Row01>
                        {dataEntered.map((option: any) => (
                          <FormCheckbox
                            checked
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </FormCheckbox>
                        ))}
                      </Row01>
                    </Form.Item>
                  </Fields>
                )}
              </CheckboxGroup>
              <ActionBar>
                <Button type="default" size="large" onClick={handleCancel}>
                  {tModalExportFile('cancel')}
                </Button>
                <Button type="primary" size="large" htmlType="submit">
                  {tModalExportFile('export')}
                </Button>
              </ActionBar>
            </Form>
          </Wrapper>
        </WizardWelcome>
      </Modal>
    </>
  );
};

export default ModalExportPotentialAudience;
