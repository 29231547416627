import React, { useCallback, useState, useEffect } from 'react';
import {
  BarChartOutlined,
  MoreOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  DownloadOutlined,
  PieChartOutlined,
} from '@ant-design/icons';

import { ColumnProps } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';

import { Menu, PaginationProps, Tag } from 'antd';

import {
  TableContent,
  ActionTab,
  ActionButton,
  ReportButton,
  TableGroup2,
  ContinueButton,
} from './styles';
import Dropdown from '../Dropdown';
import { useCustomTranslation } from '../../hooks/custom-translation';
import Button from '../Button';
import {
  FileImportPjProps,
  statusMap,
  useDeleteFileImportMutation,
} from '../../services/fileImporter';
import { Body } from '../../styles/fonts';

import { HelperDate } from '../../helpers/date';

import * as CONTANTS from '../../navigation/constants';
import FormInput from '../FormInput';
import ModalDelete from '../ModalDelete';
import { ShowFormMessage } from '../../helpers/message';
import getErrors from '../../helpers/errors';
import ModalEnrichmentDetailsPage from '../../pages/ContactImportWizard/ModalEnrichmentDetails';
import { EnrichmentProps } from '../TabGroupEnrichmentDetails/Enrichment';
import { QualitiesProps } from '../TabGroupEnrichmentDetails/Sanitization';
import FilterIcon from '../FilterIcon';
import ProgressBar from '../ProgressBar';
import Tooltip from '../Tooltip';
import { useGetAllFilesImportsPjQuery } from '../../services/fileImporterPJ';
import ModalExportFilePj from './ModalExportFilePj';
import { usePostFileExportPjMutation } from '../../services/fileExportPj';
import DescriptiveAnalysisModalPj from '../DescriptiveAnalysisModalPj';

interface MenuDropdownProps {
  id: string;
  dataSource: string;
}

const ImportsTab = ({
  setLoader,
  refetch,
}: {
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: boolean;
}) => {
  const navigate = useNavigate();
  /* const handleNavigate = () => {
    navigate('/layout-home/modal-segment-details');
  }; */

  const [fileImportId, setFileImportId] = useState<string>('');
  const [fileImportSource, setFileImportSource] = useState<string>('');
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalAnalysisOpen, setIsModalAnalysisOpen] = useState(false);
  const [customerFileName, setCustomerFileName] = useState<string>('');
  const [qualities] = useState<QualitiesProps>();
  const [enrichment] = useState<EnrichmentProps>();
  const [isModalEnrichmentOpen, setIsModalEnrichmentOpen] =
    useState<boolean>(false);

  const [deleFileImport, configDeleFileImport] = useDeleteFileImportMutation();

  const [page, setPage] = React.useState(1);

  const [search, setSearch] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState<number>(
    window.innerHeight < 900 ? 7 : 9,
  );
  const [fetchInterval, setFetchInterval] = useState<number>(2000);

  const handleChangeRowsPerPage: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize,
  ) => {
    setRowsPerPage(pageSize);
  };

  const {
    data: files,
    refetch: filesRefetch,
    isLoading: filesLoading,
  } = useGetAllFilesImportsPjQuery(
    {
      top: rowsPerPage,
      skip: page,
      orderby: { createdUtc: 'desc' },
    },
    {
      pollingInterval: fetchInterval,
    },
  );

  const [postFileExportPj, configPostFileExport] =
    usePostFileExportPjMutation();

  const { tContactManagement, tCommonWords } = useCustomTranslation(
    'contactManagement',
    'commonWords',
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModalExportFile = useCallback(() => {
    setIsModalOpen(false);
    setFileImportId('');
  }, []);

  const handleShowModalExportFile = useCallback(
    ({ id, dataSource }: MenuDropdownProps) => {
      setFileImportId(id);
      setFileImportSource(dataSource);
      setIsModalOpen(true);
    },
    [],
  );

  // const handleShowModalDelete = useCallback((id: string) => {
  //   setFileImportId(id);
  //   setIsModalDeleteOpen(true);
  // }, []);

  const handleCloseModalDelete = useCallback(() => {
    setIsModalDeleteOpen(false);
  }, []);

  const handleDeleteImport = async () => {
    await deleFileImport(fileImportId).unwrap();
  };

  const handleCloseModalAnalysis = useCallback(() => {
    setIsModalAnalysisOpen(false);
    setFileImportId('');
  }, []);

  const updateReport = () => {
    setIsModalEnrichmentOpen(false);
    setIsModalEnrichmentOpen(true);
  };

  const handleCloseModalEnrichment = () => {
    setIsModalEnrichmentOpen(false);
    setFileImportId('');
  };

  const showMessageDeleteFileImporter = useCallback(() => {
    const { isSuccess, isError, error } = configDeleFileImport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Importação deletada com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      setIsModalDeleteOpen(false);
    }
  }, [configDeleFileImport]);

  const handleOpenModalEnrichment = async (id: string) => {
    setFileImportId(id);
    setIsModalEnrichmentOpen(true);
  };

  const handleSubmit = async () => {
    await postFileExportPj({
      dataSourceId: fileImportId,
      dataSource: fileImportSource,
      fileExportTypeId: 2,
    });
    handleCloseModalEnrichment();
  };

  const showMessagePostFileExport = useCallback(() => {
    const { isSuccess, isError, error } = configPostFileExport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }

    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Seu arquivo foi gerado e está sendo processado.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
  }, [configPostFileExport]);

  useEffect(() => {
    if (fileImportId) {
      const getPositionById = (id: string) => {
        let position = 0;
        files?.data.forEach((item: any, i: number) => {
          if (item.id === id) position = i;
        });
        return position;
      };
      if (files?.data.length) {
        const positionId = getPositionById(fileImportId);
        setCustomerFileName(files.data[positionId].customerFileName || '');

        // // if (
        // //   files?.data[positionId].enrichmentRequests[0]?.qualities &&
        // //   files?.data[positionId].enrichmentRequests[0]?.descriptiveAnalysis
        // // ) {
        // //   const tempQualities = files?.data[
        // //     positionId
        // //   ].enrichmentRequests[0].qualities.map(
        // //     ({
        // //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // //       contactEnrichmentRequestId,
        // //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // //       createdUtc,
        // //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // //       modifiedUtc,
        // //       id,
        // //       infoName,
        // //       status,
        // //       ...item
        // //     }: {
        // //       contactEnrichmentRequestId: string;
        // //       createdUtc: string;
        // //       modifiedUtc: string;
        // //       id: string;
        // //       infoName: string;
        // //       status: string;
        // //     }) => {
        // //       return {
        // //         infoName: infoName.charAt(0) + infoName.slice(1).toLowerCase(),
        // //         status: status
        // //           ? status.charAt(0) + status.slice(1).toLowerCase()
        // //           : '-',
        // //         key: id,
        // //         ...item,
        // //       };
        // //     },
        // //   );

        // //   const tempEnrichment = files?.data[
        // //     positionId
        // //   ].enrichmentRequests[0].descriptiveAnalysis.map(
        // //     ({
        // //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // //       contactEnrichmentRequestId,
        // //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // //       createdUtc,
        // //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // //       modifiedUtc,
        // //       id,
        // //       infoValue,
        // //       infoName,
        // //       ...item
        // //     }: {
        // //       contactEnrichmentRequestId: string;
        // //       createdUtc: string;
        // //       modifiedUtc: string;
        // //       id: string;
        // //       infoValue: string;
        // //       infoName: string;
        // //     }) => {
        // //       return {
        // //         key: id,
        // //         infoValue: infoValue
        // //           ? infoValue.charAt(0) + infoValue.slice(1).toLowerCase()
        // //           : '',
        // //         infoName: infoName
        // //           ? infoName.charAt(0) + infoName.slice(1).toLowerCase()
        // //           : '',
        // //         ...item,
        // //       };
        // //     },
        // //   );

        // //   setEnrichment(tempEnrichment);
        // //   setQualities(tempQualities);
        // }
      }
    }
  }, [fileImportId, files, isModalEnrichmentOpen, isModalOpen]);

  useEffect(showMessagePostFileExport, [showMessagePostFileExport]);

  useEffect(showMessageDeleteFileImporter, [showMessageDeleteFileImporter]);

  useEffect(() => {
    const unreadyFiles = files?.data.filter(
      (file: any) =>
        file.fileImportStatusPjId === 1 ||
        file.fileImportStatusPjId === 3 ||
        file.fileImportStatusPjId === 4,
    );

    if (unreadyFiles?.length) {
      return setFetchInterval(120000);
    }

    return setFetchInterval(0);
  }, [files?.data]);

  useEffect(() => {
    if (refetch) {
      filesRefetch();
    }
  }, [filesRefetch, refetch]);

  useEffect(() => filesRefetch(), []);

  useEffect(() => {
    if (filesLoading) {
      return setLoader(true);
    }

    return setLoader(false);
  }, [filesLoading]);

  const MenuDropdown = ({ id, dataSource }: MenuDropdownProps) => (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Button
              type="link"
              onClick={() => handleShowModalExportFile({ id, dataSource })}
              icon={<DownloadOutlined />}
            >
              {tContactManagement('export_file')}
            </Button>
          ),
        },
        {
          key: '2',
          label: (
            <Button
              type="link"
              onClick={() => handleOpenModalEnrichment(id)}
              icon={<PieChartOutlined />}
            >
              {tContactManagement('report')}
            </Button>
          ),
        },
        {
          type: 'divider',
        },
        // {
        //   key: '3',
        //   label: (
        //     <Button
        //       type="link"
        //       onClick={() => handleShowModalDelete(id)}
        //       style={{ color: 'red' }}
        //       icon={<DeleteOutlined style={{ color: 'red' }} />}
        //     >
        //       {tContactManagement('delete_file')}
        //     </Button>
        //   ),
        // },
      ]}
    />
  );
  // const navigateToReport = () => {
  //   navigate('/layout-home/modal-enrichment-details');
  // };

  const handleContinueImport = (record: FileImportPjProps) => {
    navigate(`${CONTANTS.CONTACT_IMPORT_WIZARD_PJ}/${record.id}`);
  };

  // const handleSegmentationsAnalysis = (record: FileImportPjProps) => {
  //   navigate(`${CONTANTS.SEGMENTATION_ANALYSIS}/${record.id}`);
  // };

  const tagColor = ['warning', 'warning', 'warning', 'warning', 'success'];

  const tagIcon = [
    <SyncOutlined spin />,
    <SyncOutlined spin />,
    <></>,
    <SyncOutlined spin />,
    <CheckCircleOutlined />,
  ];

  const handleInputSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const handleDescriptiveAnalysisModal = (record: FileImportPjProps) => {
    setFileImportId(record.id);
    setFileImportSource('FileImport');
    setIsModalAnalysisOpen(true);
  };

  const getStatus = (progress: any) => {
    return statusMap[progress] || null;
  };

  const columnsTableGroup2: ColumnProps<any>[] = [
    {
      title: `${tContactManagement('source_file')}`,
      dataIndex: 'customerFileName',
      align: 'left',
      sorter: (a, b) => a.customerFileName.localeCompare(b.customerFileName),
      filterDropdown: () => (
        <FormInput
          placeholder={tContactManagement('filter_text')}
          onChange={handleInputSearch}
        />
      ),
      filterIcon: () => <FilterIcon isFiltered={!!search} />,
    },
    {
      title: `${tContactManagement('total_lines')}`,
      dataIndex: 'contactsCount',
      align: 'left',
      width: '216px',
      sorter: (a, b) => a.contactsCount - b.contactsCount,
    },
    {
      title: `${tContactManagement('date')}`,
      dataIndex: 'createdUtc',
      align: 'left',
      width: '216px',

      sorter: (a, b) =>
        new Date(a.createdUtc).getTime() - new Date(b.createdUtc).getTime(),
      render: value => {
        return <Body>{HelperDate.formateDate(value)}</Body>;
      },
    },
    {
      title: 'Progresso',
      render: (_, record: any) => {
        const status = getStatus(record?.progress);
        return <ProgressBar fileImportStatusId={status} size="small" />;
      },
    },
    {
      title: `${tContactManagement('status')}`,
      dataIndex: 'fileImportStatusPjId',
      align: 'left',
      width: '216px',
      sorter: (a, b) =>
        a.fileImportStatusPj.friendlyName.localeCompare(
          b.fileImportStatusPj.friendlyName,
        ),
      render: (text: number, record: any) => {
        const status = record?.fileImportStatusPj;
        return (
          <>
            <Tag color={tagColor[status?.id]} icon={tagIcon[status?.id]}>
              {status?.friendlyName}
            </Tag>
            {status === 4 && (
              <Tooltip title={record?.infoErrors[0] ?? ''}>
                <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: `${tContactManagement('actions')}`,
      dataIndex: 'acoes',
      align: 'left',
      width: '246px',
      render: (value: number, record: FileImportPjProps) => {
        // record.enrichmentRequests[record.enrichmentRequests.length - 1];
        return (
          <ActionTab>
            {record.fileImportStatusPjId === 4 ? (
              <>
                <>
                  {/* {createdDate.isSameOrAfter(referenceDate) && (
                    <ReportButton
                      type="default"
                      icon={<BarChartOutlined />}
                      onClick={() => handleSegmentationsAnalysis(record)}
                    >
                      {tContactManagement('segmentation_analysis')}
                    </ReportButton>
                  )} */}

                  <ReportButton
                    type="default"
                    icon={<BarChartOutlined />}
                    onClick={() => handleDescriptiveAnalysisModal(record)}
                  >
                    {tContactManagement('descriptive_analysis')}
                  </ReportButton>
                </>
                <Dropdown
                  overlay={
                    <MenuDropdown id={record.id} dataSource="FileImport" />
                  }
                >
                  <ActionButton type="default" icon={<MoreOutlined />} />
                </Dropdown>
              </>
            ) : null}

            {record?.fileImportStatusPjId === 2 && (
              <>
                <ContinueButton onClick={() => handleContinueImport(record)}>
                  {tCommonWords('continue')}
                </ContinueButton>
                <Dropdown
                  overlay={
                    <MenuDropdown id={record.id} dataSource="FileImport" />
                  }
                >
                  <ActionButton type="default" icon={<MoreOutlined />} />
                </Dropdown>
              </>
            )}
            {record?.fileImportStatusPjId !== 2 &&
              record?.fileImportStatusPjId !== 4 && (
                <>
                  {/* <ContinueButton onClick={() => handleContinueImport(record)}>
                    {tCommonWords('continue')}
                  </ContinueButton> */}
                  <Dropdown
                    overlay={
                      <MenuDropdown id={record.id} dataSource="FileImport" />
                    }
                  >
                    <ActionButton type="default" icon={<MoreOutlined />} />
                  </Dropdown>
                </>
              )}
          </ActionTab>
        );
      },
    },
  ];

  return (
    <TableContent>
      <TableGroup2
        dataSource={files?.data}
        columns={columnsTableGroup2}
        rowKey="id"
        pagination={{
          defaultPageSize: rowsPerPage,
          onShowSizeChange: handleChangeRowsPerPage,
          total: files?.meta?.size,
          onChange: currentPage => setPage(currentPage),
        }}
      />
      <ModalExportFilePj
        fileImportId={fileImportId}
        fileImportSource={fileImportSource}
        visible={isModalOpen}
        onCancel={handleCloseModalExportFile}
        onOk={handleCloseModalExportFile}
      />
      <ModalDelete
        visible={isModalDeleteOpen}
        onCancel={handleCloseModalDelete}
        title={`${tContactManagement('delete_file')}`}
        deleteButton={`${tContactManagement('delete_file')}`}
        onOk={handleDeleteImport}
      >
        Ao executar a exclusão, todos os registros deste arquivo serão removidos
        do sistema.
      </ModalDelete>
      <DescriptiveAnalysisModalPj
        fileId={fileImportId}
        fileTypeDataSource={fileImportSource}
        visible={
          fileImportId !== '' && fileImportSource !== '' && isModalAnalysisOpen
        }
        onCancel={handleCloseModalAnalysis}
      />

      {qualities && enrichment && (
        <ModalEnrichmentDetailsPage
          visible={isModalEnrichmentOpen}
          customerFilename={customerFileName}
          qualities={qualities}
          enrichment={enrichment}
          onUpdate={updateReport}
          onOk={handleSubmit}
          onCancel={handleCloseModalEnrichment}
        />
      )}
    </TableContent>
  );
};

export default ImportsTab;
