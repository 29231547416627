import { Rule } from 'antd/lib/form';
import { useCustomTranslation } from '../../hooks/custom-translation';

export const NotZero = (): Rule => {
  const { tValidationMessages } = useCustomTranslation('validationMessages');
  return {
    validator: (_, value) => {
      if (value === '' || value === null || value === undefined) {
        return Promise.reject(new Error(tValidationMessages('required')));
      }
      if (Number(value) === 0) {
        return Promise.reject(new Error(tValidationMessages('equal_zero')));
      }
      return Promise.resolve();
    },
  };
};
