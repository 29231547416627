import React, { useCallback, useEffect, useState } from 'react';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  MoreOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Menu, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { HelperDate } from '../../helpers/date';

import { useCustomTranslation } from '../../hooks/custom-translation';
import {
  useDeleteFileExportPjMutation,
  useGetAllFilesExportsPjQuery,
  useGetPresignedUrlFileExportPjQuery,
} from '../../services/fileExportPj';
import { Body } from '../../styles/fonts';
import Button from '../Button';
import Dropdown from '../Dropdown';
import FormInput from '../FormInput';
import ModalDelete from '../ModalDelete';
import {
  ActionButton,
  ActionTab,
  DownloadButton,
  TableContent,
  TableGroup,
} from './styles';
import { ShowFormMessage } from '../../helpers/message';
import getErrors from '../../helpers/errors';
import FilterIcon from '../FilterIcon';

interface MenuDropProps {
  id: string;
}

const ExportsTabPj = ({
  refetch,
  setLoader,
}: {
  refetch: boolean;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { tContactManagement } = useCustomTranslation(
    'contactManagement',
    'commonWords',
  );

  const [search, setSearch] = useState('');

  const [fileId, setFileId] = useState('');
  const [skipping, setSkipping] = useState(true);
  const [fetchInterval, setFetchInterval] = useState<number>(0);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const [page] = useState(1);

  const {
    data: exportFilesPj,
    refetch: exportsRefetch,
    isLoading: exportsLoading,
  } = useGetAllFilesExportsPjQuery(
    {
      top: 6,
      skip: page,
      orderby: { createdUtc: 'desc' },
      filter: search ? [{ filter: { fileName: search }, type: 'contain' }] : [],
      custom: `$expand=type,status,fileimport,segmentation,contactenrichmentrequest($expand=fileimport)`,
    },
    { pollingInterval: fetchInterval },
  );

  console.log('exportFilesPj: ', exportFilesPj);
  const { data: downloadUrl, isSuccess } = useGetPresignedUrlFileExportPjQuery({
    fileExportId: fileId,
  });

  const [deleteFileExportPj, configDeleteFileExport] =
    useDeleteFileExportPjMutation();

  const showMessageDeleteFileExport = useCallback(() => {
    const { isSuccess: deleteSuccess, isError, error } = configDeleteFileExport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Exportação excluída com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (deleteSuccess) {
      setIsModalDeleteOpen(false);
    }
  }, [configDeleteFileExport]);

  useEffect(showMessageDeleteFileExport, [showMessageDeleteFileExport]);

  const handleShowModalDelete = useCallback((id: string) => {
    setFileId(id);
    setIsModalDeleteOpen(true);
  }, []);

  const handleDeleteExport = async () => {
    await deleteFileExportPj(fileId).unwrap();
  };

  const handleCloseModalDelete = useCallback(() => {
    setIsModalDeleteOpen(false);
  }, []);

  const handleDownload = (id: string) => {
    setFileId(id);
    setSkipping(!skipping);
  };

  useEffect(showMessageDeleteFileExport, [showMessageDeleteFileExport]);

  useEffect(() => {
    if (isSuccess) {
      window.location = downloadUrl;
    }
  }, [isSuccess]);

  useEffect(() => {
    const unreadyFiles = exportFilesPj?.data.filter(
      (file: any) => file.fileExportStatusId === 1,
    );

    if (unreadyFiles?.length) {
      return setFetchInterval(120000);
    }

    return setFetchInterval(0);
  }, [exportFilesPj?.data]);

  useEffect(() => {
    if (exportsLoading) {
      return setLoader(true);
    }

    return setLoader(false);
  }, [exportsLoading]);

  useEffect(() => {
    if (refetch) {
      exportsRefetch();
    }
  }, [refetch]);

  const tagColor = {
    Processando: 'warning',
    'Pronto para Download': 'success',
    Erro: 'error',
  };

  const tagIcon = {
    Processando: <SyncOutlined spin />,
    'Pronto para Download': <CheckCircleOutlined />,
    Erro: <CloseCircleOutlined />,
  };

  const MenuDrop = ({ id }: MenuDropProps) => (
    <Menu
      items={[
        {
          key: '0',

          label: (
            <Button
              type="link"
              onClick={() => handleShowModalDelete(id)}
              style={{ color: 'red' }}
              icon={<DeleteOutlined style={{ color: 'red' }} />}
            >
              {tContactManagement('delete_file')}
            </Button>
          ),
        },
      ]}
    />
  );

  const handleInputSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const columnsExportsTable: ColumnProps<any>[] = [
    {
      title: 'Arquivo',
      dataIndex: 'fileName',
      align: 'left',
      sorter: (a, b) => a.fileName.localeCompare(b.fileName),
      render: (text, record) => {
        return <Body>{record?.fileName || '-'}</Body>;
      },
      filterDropdown: () => (
        <FormInput
          placeholder={tContactManagement('filter_text')}
          onChange={handleInputSearch}
        />
      ),
      filterIcon: () => <FilterIcon isFiltered={!!search} />,
    },
    // {
    //   title: 'Categoria',
    //   dataIndex: 'type',
    //   align: 'left',
    //   width: '216px',
    //   sorter: (a, b) => a.type.friendlyName.localeCompare(b.type.friendlyName),
    //   render: (value: FileExportTypeProps) => (
    //     <Body>{value?.friendlyName}</Body>
    //   ),
    // },
    {
      title: `${tContactManagement('date')}`,
      dataIndex: 'createdUtc',
      align: 'left',
      width: '216px',
      sorter: (a, b) =>
        new Date(a.createdUtc).getTime() - new Date(b.createdUtc).getTime(),
      render: (text: Date) => (
        <Body weight="regular">{HelperDate.formateDate(text)}</Body>
      ),
    },
    {
      title: `${tContactManagement('status')}`,
      dataIndex: 'status',
      align: 'left',
      width: '216px',
      sorter: (a, b) =>
        a.fileExportStatus.friendlyName.localeCompare(
          b.fileExportStatus.friendlyName,
        ),
      render: (value, record: any) => {
        const friendlyName = record?.fileExportStatus
          ?.friendlyName as keyof typeof tagColor;
        return (
          <Tag color={tagColor[friendlyName]} icon={tagIcon[friendlyName]}>
            {friendlyName}
          </Tag>
        );
      },
    },
    {
      title: `${tContactManagement('actions')}`,
      dataIndex: 'acoes',
      align: 'left',
      width: '216px',
      render: (value, record) => {
        return (
          <ActionTab>
            {record?.fileExportStatus.friendlyName ===
            'Pronto para Download' ? (
              <DownloadButton
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(record.id)}
              >
                Download
              </DownloadButton>
            ) : null}
            <Dropdown overlay={<MenuDrop id={record.id as string} />}>
              <ActionButton type="default" icon={<MoreOutlined />} />
            </Dropdown>
          </ActionTab>
        );
      },
    },
  ];

  return (
    <TableContent>
      <TableGroup
        dataSource={exportFilesPj?.data}
        columns={columnsExportsTable}
        rowKey="id"
      />
      <ModalDelete
        visible={isModalDeleteOpen}
        onCancel={handleCloseModalDelete}
        title={`${tContactManagement('delete_file')}`}
        deleteButton={`${tContactManagement('delete_file')}`}
        onOk={handleDeleteExport}
      >
        Ao executar a exclusão, todos os registros deste arquivo serão removidos
        do sistema.
      </ModalDelete>
    </TableContent>
  );
};

export default ExportsTabPj;
