import React from 'react';
import { H5 } from '../../../../styles/fonts';
import { LineBar } from '../../../Bar/LineBar/styles';
import { Content02, Row011, Data022 } from './styles';

interface DistributionData {
  key: string;
  documentCount: number;
  percent: number;
}

interface CnaeDescriptionProps {
  descricaoCnaeDistribution: DistributionData[];
}

const abbreviateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

const CnaeDescriptionTab = ({
  descricaoCnaeDistribution,
}: CnaeDescriptionProps): JSX.Element => {
  const renderNoDataMessage = () => <H5 weight="regular">Sem registros</H5>;

  const CnaeDistribution: React.FC = (): JSX.Element => {
    const data = descricaoCnaeDistribution;

    if (data.length === 0) return renderNoDataMessage();

    const chartHeight = data.length === 1 ? 100 : 400;

    const config = {
      data: data.map(item => ({
        description: abbreviateText(item.key, 30),
        fullDescription: item.key,
        percentage: item.percent,
      })),
      xField: 'percentage',
      yField: 'description',
      barWidthRatio: 0.6,
      columnWidthRatio: 0.8,
      height: chartHeight,
      width: 470,
      animation: false,
      padding: [30, 20, 30, 200],
      meta: {
        description: { alias: 'Regime Tributário' },
        percentage: {
          alias: 'Porcentagem (%)',
          formatter: (val: number) => `${val}%`,
        },
      },
      tooltip: {
        customContent: (title: string, items: any[]) => {
          if (!items || items.length === 0) return null;

          const record = items[0]?.data;
          return (
            <div
              style={{
                padding: '8px',
                background: '#fff',
                borderRadius: '4px',
              }}
            >
              <h4>{record?.fullDescription}</h4>
              <p>
                <strong>Porcentagem:</strong> {record?.percentage}%
              </p>
            </div>
          );
        },
      },
    };

    return <LineBar {...config} />;
  };

  return (
    <Content02>
      <Row011>
        <Data022>
          <H5 weight="medium">Descrição CNAE</H5>
          <CnaeDistribution />
        </Data022>
      </Row011>
    </Content02>
  );
};

export default CnaeDescriptionTab;
