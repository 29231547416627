import React, { useEffect, useMemo } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import UploadFile from '../../components/ImportFileStepsPJ/UploadFile';
import CombineFields from '../../components/ImportFileStepsPJ/CombineFields';
import FinishImport from '../../components/ImportFileStepsPJ/FinishImport';
import {
  StepsItemGroupStepsContent,
  StepsItemGroupStep,
  StepsItemGroupWraper,
  StepsItemGroup,
} from './styles';
import { useCustomTranslation } from '../../hooks/custom-translation';
import {
  Body,
  ButtonHeader,
  Container,
  Content,
  PageHeader,
} from '../../styles/contents';
import { H3 } from '../../styles/fonts';

import * as CONSTANTS from '../../navigation/constants';
import { useGetDashboardDataQuery } from '../../services/dashboard';

const ContactImportWizardPJ = () => {
  const [current, setCurrent] = React.useState(0);
  const { id } = useParams();
  const { data: files } = useGetDashboardDataQuery({});

  const navigate = useNavigate();

  const [importerId, setImporterId] = React.useState('');
  const change = (a: any) => {
    setCurrent(a);
  };

  useEffect(() => {
    if (files && files.length) {
      // const customerPF = files.find((file: any) => file.customerType === 'PF');
      const customerPJ = files.find((file: any) => file.customerType === 'PJ');
      const checkUpgrade = (customer: any) => {
        if (customer?.enrichment >= customer?.maxQuantityEnrichment) {
          navigate(CONSTANTS.UPGRADE);
        }
      };

      // if (customerPF) {
      //   checkUpgrade(customerPF);
      // }

      if (customerPJ) {
        checkUpgrade(customerPJ);
      }
    }
  }, [files, navigate]);

  useEffect(() => {
    console.log('id: ', id);
    if (id) {
      setCurrent(1);
    }
  }, [id]);

  const { tImportContact } = useCustomTranslation('importContact');

  const steps = useMemo(
    () => [
      {
        title: `${tImportContact('upload_file')}`,
        content: (
          <UploadFile
            change={change}
            setImporterId={setImporterId}
            importerId={importerId}
          />
        ),
      },
      {
        title: `${tImportContact('combine_fields')}`,
        content: <CombineFields change={change} importerId={importerId} />,
      },
      {
        title: `${tImportContact('import')}`,
        content: <FinishImport importerId={importerId} />,
      },
    ],
    [importerId, tImportContact],
  );

  const backToContacts = () => {
    navigate(CONSTANTS.DATABASE_MANAGEMENT_PJ);
  };
  return (
    <Container>
      <PageHeader>
        <ButtonHeader
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={backToContacts}
        />
        <H3 weight="medium">{tImportContact('import_contacts_pj')}</H3>
      </PageHeader>
      <Body>
        <Content>
          <StepsItemGroupWraper>
            <StepsItemGroup
              current={current}
              type="default"
              direction="horizontal"
            >
              <StepsItemGroupStep title={steps[0].title} />
              <StepsItemGroupStep title={steps[1].title} />
              <StepsItemGroupStep title={steps[2].title} />
            </StepsItemGroup>
            <StepsItemGroupStepsContent>
              {steps[current].content}
            </StepsItemGroupStepsContent>
          </StepsItemGroupWraper>
        </Content>
      </Body>
    </Container>
  );
};

export default ContactImportWizardPJ;
